import React, {useEffect} from 'react';
import Loader from 'components/Loader';
import getEnvironmentName from 'utils/getEnvironmentName';
import {ENVIRONMENTS} from 'am-constants';
import Hubspot from './components/Hubspot';
import useLoggedInUserQuery from './hooks/useLoggedInUserQuery';
import UserAppRouter from './components/UserAppRouter';
import UserProvider from './providers/UserProvider';

function UserAppContainer () {
  const {user, loading, error} = useLoggedInUserQuery();
  const environment = getEnvironmentName();
  useEffect(() => {
    if (error) {
      throw new Error('Error loading the user:', error);
    }
  }, [error]);

  if (loading) {
    return <Loader />;
  }

  return (
    <UserProvider user={user}>
      {environment === ENVIRONMENTS.PRODUCTION && <Hubspot user={user} />}
      <UserAppRouter />
    </UserProvider>
  );
}

export default UserAppContainer;
