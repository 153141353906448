import {ENVIRONMENTS} from 'am-constants';

function getEnvironmentName () {
  if (process.env.NODE_ENV === 'ci') {
    return ENVIRONMENTS.CI;
  }

  if (window.location.hostname.includes('localhost')) {
    return ENVIRONMENTS.DEVELOPMENT;
  }

  if (window.location.hostname === 'app.airmanual.co') {
    return ENVIRONMENTS.PRODUCTION;
  }

  if (window.location.hostname === 'app.test.airmanual.co') {
    return ENVIRONMENTS.TEST;
  }

  return 'unknown';
}

export default getEnvironmentName;
