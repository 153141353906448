const roleAndPermissionConstants = require("./roleAndPermissionConstants");

const constants = {
  FREE_TRIAL_DAYS: 7,
  COOKIE_PURPOSE: {
    // For more details on cookie purposes, see https://www.iubenda.com/en/help/1229-manual-tagging-blocking-cookies
    NECESSARY: 1,
    FUNCTIONALITY: 2, // e.g. user support
    ENHANCED: 3, // e.g. commenting and social integrations
    ANALYTICS: 4,
    ADVERTISING: 5, // e.g. for remarketing
  },
  DAYS_OF_WEEK: {
    MON: "mon",
    TUE: "tue",
    WED: "wed",
    THU: "thu",
    FRI: "fri",
    SAT: "sat",
    SUN: "sun",
  },
  DAYS_OF_WEEK_ORDER: {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
  },
  DRAGGABLE_TYPES: {
    GRID_ROW: "grid-row",
  },
  ERROR_REASON: {
    WEAK_PASSWORD: 1,
  },
  EMAIL_TYPE: {
    USER_ADDED_TO_WORKSPACE: 1,
    USER_RESET_PASSWORD: 2,
    AUTOMATION: 3,
  },
  INSTANCE_OF_DAY_IN_MONTH: {
    DAY_NUMBER: "day_number",
    FIRST: "first",
    SECOND: "second",
    THIRD: "third",
    FOURTH: "fourth",
    LAST: "last",
  },
  FREQUENCY: {
    DAILY: "daily",
    WEEKLY: "weekly",
    MONTHLY: "monthly",
    YEARLY: "yearly",
  },
  TRIGGER: {
    CREATE_RESPONSE: "create_response",
    SUBMIT_RESPONSE: "submit_response",
  },
  ACTION: {
    WEBHOOK: "webhook",
    EMAIL: "email",
    SLACK: "slack",
    TEAMS: "teams"
  },
  CONTENT_ACTION_NEEDED: {
    NEEDS_CONTENT: 1,
    NEEDS_UPDATES: 2,
    NEEDS_REVIEW: 3,
  },
  CONTENT_PRIORITY: {
    LOW: "low",
    MEDIUM: "medium",
    HIGH: "high",
    VERY_HIGH: "very_high",
  },
  ITEM_TYPE: {
    WORKSPACE: 1,
    PRIVATE_SPACE: 2,
    DRAFT_SPACE: 3,
    FOLDER: 4,
    DOCUMENT: 5,
    FORM: 6,
    CHECKLIST: 7,
    LINK: 8,
    SECTION: 9,
  },
  RESPONSE_DATA_TYPE: {
    PROCESS_STEP: 1,
    CHECKBOX: 2,
    INPUT: 3,
  },
  RESPONSE_TYPE: {
    ALL: "all",
    ACTIVE: "active",
    COMPLETED: "completed",
  },
  STATUS_CODE: {
    // Standard: https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    CONFLICT: 409,
    UNSUPPORTED_MEDIA_TYPE: 415,
    IM_A_TEAPOT: 418,
    COULD_NOT_PROCESS: 422,
    SERVER_ERROR: 500,
    // Custom
  },
  DB_ERROR_CODE: {
    UNIQUE_CONSTRAINT_VIOLATION: "23505",
  },
  FORBIDDEN_ERRORS: {
    NOT_WORKSPACE_TEAM_MEMBER: 1,
    MISSING_WORKSPACE_PERMISSION: 2,
  },
  MANAGEMENT_ROLE: {
    DOCUMENTER: 1,
    REVIEWER: 2,
    TASK_OWNER: 3,
  },
  SECRET_KEYS: {
    DATABASE_APP_PWD: "database-app-pwd",
    POSTMARK_API_KEY: "postmark-api-key",
    GOOGLE_CALENDAR_TOKENS: "google-calendar-tokens",
    OPENAI_API_KEY: "openai-api-key",
    HUBSPOT_CLIENT_TOKEN: "hubspot-client-token",
  },
  SHARER_MODAL_OPTIONS: {
    VIEW_AND_USE: 1,
    COMMENT: 2,
    EDIT: 3,
    VIEW_DATA: 4,
    SHARE: 5,
  },
  SUPPORTED_IMG_TYPES: [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/webp",
    "image/heic",
    "image/heif",
  ],
  FEATURE_FLAGS: [
    "2022_09_dashboard",
    "slateCollaborate",
    "automations",
    "ai",
    "comments",
    "wizard",
    "full_text_search",
  ],
  ENVIRONMENTS: {
    PRODUCTION: "production",
    TEST: "test",
    DEVELOPMENT: "development",
    CI: "ci",
  },
  AI_QUERY_CONFIG_ID: {
    GENERATE_SINGLE_CHECKLIST: 1,
    GENERATE_MULTIPLE_CHECKLISTS: 2,
    WORKSPACE_EMBEDDINGS: 3,
  },
  DOCUMENT_WARNINGS: {
    STEP_TITLE_VERB: 'step_title_verb',
    STEP_TITLE_LONG: 'step_title_long',
    STEP_NO_HELP_BLOCKS: 'step_no_help_blocks',
    TITLE_HOW_TO: 'title_how_to',
    TITLE_VERB: 'title_verb',
    TITLE_LONG: 'title_long',
    IMAGES_TOO_MANY: 'images_too_many',
    INTRO_NO_HELP_BLOCKS: 'intro_no_help_blocks',
  }
};

const { ITEM_TYPE } = constants;
const FOLDER_ITEM_TYPES = [ITEM_TYPE.WORKSPACE, ITEM_TYPE.FOLDER];
const PAGE_ITEM_TYPES = [
  ITEM_TYPE.FORM,
  ITEM_TYPE.CHECKLIST,
  ITEM_TYPE.DOCUMENT,
];

module.exports = {
  ...constants,
  FOLDER_ITEM_TYPES,
  PAGE_ITEM_TYPES,
  ...roleAndPermissionConstants,
};
