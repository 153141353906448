import React from 'react';
import PropTypes from 'prop-types';
import {useTable, useSortBy} from 'react-table';
import Table, {TableCell, TableHeaderCell, TableRow} from 'components/Table';
import tw, {styled, css} from 'twin.macro';
import {AiFillCaretDown, AiFillCaretUp} from 'react-icons/ai';
import Truncate from './Truncate';

ReactTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSortingDisabled: PropTypes.bool,
};

function ReactTable (props) {
  const {
    columns,
    data,
    isSortingDisabled,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      disableSortBy: isSortingDisabled,
    },
    useSortBy
  );

  // Render the UI for your table
  return (
    <Table {...getTableProps()}>
      <thead>
        <TableRow>
          {headers.map((column, i) => (
            <StyledTableHeaderCell
              key={`header${i}`}
              {...column.getHeaderProps(isSortingDisabled ? {} : column.getSortByToggleProps())}
              disabled={isSortingDisabled}
            >
              <div tw="flex items-center">
                <Truncate>{column.render('Header')}</Truncate>
                <div tw="flex-1"></div>
                {column.isSorted && !column.isSortedDesc && <AiFillCaretDown tw="text-gray-500" size="14" />}
                {column.isSorted && column.isSortedDesc && <AiFillCaretUp tw="text-gray-500" size="14" />}
              </div>
            </StyledTableHeaderCell>
          ))}
        </TableRow>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow key={row.original.id} {...row.getRowProps()} onClick={row.original.onClick}>
              {row.cells.map((cell, j) => <TableCell key={`cell${j}`} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>)}
            </TableRow>
          );
        })}
      </tbody>
    </Table>
  );
}

const StyledTableHeaderCell = styled(TableHeaderCell)((props) => [
  tw`
    select-none
  `,
  !props.disabled && tw`
    cursor-pointer
  `,
  css`
    min-width: 160px;
  `,
]);

export default ReactTable;
