import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import tw, {styled, css} from 'twin.macro';
import {useSlateStatic} from 'slate-react';
import selectEndOfEditor from './utils/selectEndOfEditor';

EditableWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function EditableWrapper (props) {
  const {children} = props;

  const editableRef = useRef();
  const editor = useSlateStatic();

  const selectEnd = () => selectEndOfEditor(editor);

  return (
    <Wrapper
      data-test-id="slate-editor-wrapper"
      offsetTop={editableRef.current && editableRef.current.offsetTop}
      ref={editableRef}>
      {children}
      <FixedSpacer onClick={selectEnd} />
      <FlexSpacer onClick={selectEnd} />
    </Wrapper>
  );
}

const Wrapper = styled.div((props) => [
  tw`
    flex
    flex-col
  `,
  props.offsetTop && css`
    min-height: calc(100vh - ${props.offsetTop}px);
  `,
]);

// A minimum amount of space to show below the editor. The height here mirrors
// the margin above the document.
const FixedSpacer = styled.div((props) => [
  tw`
    cursor-text
  `,
  css`
    height: 5rem;
  `,
]);

// A spacer that fills up any remaining space. This allows the user to click any
// where below the editor and then start typing!
const FlexSpacer = styled.div((props) => [
  tw`
    flex-1
    cursor-text
  `,
]);

export default EditableWrapper;
